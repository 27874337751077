@font-face{
	font-family : "SugarBomb";
	src:  url("./font/SugarBomb.ttf");
}

body{
    border-width: 0;
    background-color: #111;
    font-family: SugarBomb !important;
    // background-image : url("./images/background.png");
    // background-blend-mode: saturation;

    &::-webkit-scrollbar{
		width : 4px;
	}

	&::-webkit-scrollbar-track{
		background-color: #e4e4e4;
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #555;
	}
}

.main-background{
    z-index: -1;
    position: fixed;
    width : 100vw;
    height : 100vh;
    opacity: 0.05;
    img{
        width: 100%;
        height: 100%;
    }
}

.back-group{
    min-height: calc(100vh - 80px);
    padding-left : 30px;
    padding-right: 30px;
    .penguin-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-logo{
            img{
                width : 80px
            }
        }
    }

    .main-logo{
        text-align: center;
        padding-bottom: 20px;
        position: relative;
        height: 100px;
        .pad{
            @media (max-width: 800px) {
                height: 20px;
            }
        }

        .wallet-position{
            position : absolute;
            top : 30px;
            right : 0;

            @media (max-width : 800px){
                position : static;
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .penguin-title{
        .title{
            font-size : 40px;
            font-weight: bold;
            color : rgb(9, 235, 96);

            @media (max-width : 800px) {
                font-size: 30px;
                text-align: center;
            }
        }
        .description{
            font-size : 24px;
            font-family: 'Courier New', Courier, monospace;
            color : rgb(161, 196, 33);

            @media (max-width : 1200px) {
                font-size: 20px;
            }

            @media (max-width : 800px) {
                font-size: 16px;
            }
        }
    }
    
    .explanation-panel{
        color : #ded0ae;
        max-width: 800px;
        font-size: 18px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .progress-panel{
        text-align: center;
        font-family: 'Courier New', Courier, monospace;
        color :rgb(201, 183, 183);
        .progress{
            margin-bottom : 40px;
            height : 15px;
            border-width: 0;
            border-radius: 7px;
            font-size : 14px;
    
            .progress-bar{
                border-width: 0;
                border-radius: 7px;
                color: rgb(231, 231, 205);
                background-image: linear-gradient(to right, rgb(40, 75, 192), rgb(0,148,71));
            }
        }
    }

    .main-panel{
        text-align: center;
        padding : 0 60px 30px 60px;
        .main-panel-header{
            border-width: 0;
            border-radius: 0 32px 32px 32px;
            background-color: rgba(12,44,25,0.6);
            width: 100%;
            // height: 120px;
            padding : 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            color : #ded0ae;
            font-weight: bold;
            margin-bottom: 40px;
            .main-panel-header-name{
                font-size: 24px;
            }
            .main-panel-header-value{
                font-size: 24px;
                .main-panel-header-amount{
                    font-size : 30px;
                    font-family: Arial, Helvetica, sans-serif;
                }
            }

            @media (max-width: 556px) {
                flex-direction: column;
                justify-content: center;
            }
        }

        .main-panel-content{
            border-radius: 32px;
            background-image: linear-gradient(rgba(0,148,71,0.4), rgba(0,77,38,0.4));
            // filter: brightness(150%);
            min-height: 600px;
            padding : 32px;
            margin-bottom: 30px;
            .main-panel-content-header{
                height : 80px;
                display: flex;
                align-items: center;
                justify-content: center;

                div{
                    img{
                        height : 60px;
                        filter: brightness(100%);
                        opacity: 1;
                    }
                }
            }
            hr{
                height : 2px;
                color : #ded0ae;
            }
            .main-panel-content-body{
                overflow: auto;
                height : 600px;
                
                &::-webkit-scrollbar{
                    width : 8px;
                }

                &::-webkit-scrollbar-track{
                    background-color: #e4e4e4;
                    border-radius: 100px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #777;
                    border-radius: 100px;
                }

                .loading-bar{
                    padding-top: 100px;
                }

                .nft-panel-content{
                    display: flex;
                    flex-wrap: wrap;
                    // justify-content: flex-start;
                    .nft{
                        flex-grow : 1;
                        min-width: 40px;
                        margin : 3px;
                        img{
                            width: 165px;
                            height : 165px;
                            border-radius: 10px;
                            &.lock-border{
                                opacity: 0.5;
                                border : 3px solid #b922b9;
                            }
                            &.normal-border{
                                border : 3px solid #e9ffc5;
                            }   
                            &.red-border{
                                border : 3px solid rgb(186, 44, 44);
                            }
                        }
                        
                        p{
                            font-family: Arial, Helvetica, sans-serif;
                            font-size : 12px;
                            margin-top: 2px;
                            margin-bottom: 0;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .main-panel-actions{
            display : flex;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: row;
            @media (max-width : 800px){
                flex-direction: column;
                justify-content: center;
            }
            .wrap-stake{
                width : 30%;
                min-width: 170px;
                text-align: center;

                @media (max-width : 800px){
                    width: 100%;
                }
            }

            .wrap-unstake{
                width: 25%;
                min-width: 160px;
                text-align: center;

                @media (max-width : 800px){
                    width: 100%;
                }
            }
            .btn{
                width: 100%;
                height : 50px;
                font-size: 20px;
                border-radius: 16px;
                font-weight: bold;
                margin : 3px;
                @media (max-width : 800px){
                    width: 70%;
                }
                @media (max-width : 500px){
                    font-size: 16px;
                }
            }
            .btn-stake, .btn-unstake{
                color : #00b355;
                border: 4px solid rgba(26,178,101,0.9);;
            }
            .btn-stake-all, .btn-unstake-all{
                color : #ded0ae;
                border-width : 0;
                background-image: linear-gradient(to right, rgba(0,81,40,0.3) , rgba(0,156,75,0.9));
            }
            .btn-claim{
                color : #ded0ae;
                border-width: 0;
                background-color: #721c2d;
            }
        }

    }

    .social-button-group{
        text-align: center;

        .social-button{
            width: 50px;
            margin : 0 20px;
            display: inline-block;
            cursor : pointer
        }
    }
}

.community-header{
    display: none;
    text-align: center;
    @media (max-width : 800px){
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        padding-bottom: 20px;
    }

    a{
        margin: 4px 12px;
    }
}

.footer{
    font-family: Arial, Helvetica, sans-serif;
    height:80px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    color : #ded0ae;
}

.footer{
	margin : 0px;
	height : 80px;
	font-size : 18px;
	line-height: 20px;
	font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	padding : 0 32px;

	display : flex;
	justify-content: space-between;
	align-items: center;

    @media (max-width : 800px){
        flex-direction: column;
        justify-content: center;
    }

    .community-part{
        @media (max-width : 800px){
            display: none;
        }
        .twitter-link{
            cursor : pointer
        }
        a{
            margin: 4px 12px;
        }
    }
}

.disclaimer-panel{
	text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;

    .disclaimer-content{
        max-width: 800px;

        &:focus-visible{
            outline: none;
        }
    
        h2{
            text-align: center;
            font-size: 30px;
            color: #53fdca;
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        }
    
        p{
            font-family: 'Courier New', Courier, monospace;
            max-height: 40vh;
            overflow-y: auto;
            padding: 0 8px;
            color: #d5d5df;
            font-size: 20px;
    
            &::-webkit-scrollbar{
                width : 4px;
            }
        
            &::-webkit-scrollbar-track{
                background-color: #e4e4e4;
                border-radius: 4px;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: #d5d5df;
                border-radius: 4px;
            }
        }
    
        input{
            text-align: right;
            font-family: 'Courier New', Courier, monospace;
            font-size: 30px;
            font-weight: bold;
            min-width: 100px;
        }
    
        span{
            font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
        }
    
        .btn-agree{
            margin-top: 20px;
            width: 180px;
            border-radius: 0.8rem;
            font-size: 20px;
            color: white
        }

        @media (max-width: 512px) {
            p{
                font-size: 14px;
            }
            input{
                font-size: 20px;
            }
            span{
                font-size: 12px;
            }
        }
    }


}